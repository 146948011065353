import * as PIXI from 'pixi.js';
import { tool } from './tool';

export interface TetrisInstance {
  Destroy(): void;
  SetGemStyle(gemStyle: number): void;
  SetToolsConf(newTools: string[]): void;
  SetClearToolSelected(selected: boolean): void;
  GetLevelConfig(): any;
  SetLevelConfig(data: any): void;
}
export function InitTool(parentElement: HTMLDivElement | null): TetrisInstance {
  console.log('Init TOOOOOL');
  const app = new PIXI.Application({
    width: 400,
    height: 400,
    antialias: true,
    transparent: true,
    // backgroundColor: 0xaaaaaa,
  });
  parentElement && parentElement.appendChild(app.view);
  var toolInstance = new tool(app);
  let tetrisInstance: TetrisInstance = {
    Destroy: () => {
      toolInstance.Destroy();
      app.destroy(true);
      console.log('destroy the app');
    },
    SetGemStyle: (gemStyle: number) => {
      toolInstance.SetGemStyle(gemStyle);
    },
    SetToolsConf: (newTools: string[]) => {
      let conf: { hasGlass: boolean; hasBoulder: boolean; hasGem: boolean } = {
        hasGlass: false,
        hasBoulder: false,
        hasGem: false,
      };
      conf.hasGlass = newTools.find((el) => el === 'glass') !== undefined;
      conf.hasBoulder = newTools.find((el) => el === 'boulder') !== undefined;
      conf.hasGem = newTools.find((el) => el === 'object') !== undefined;
      toolInstance.SetToolsConf(conf);
    },
    SetClearToolSelected: (selected: boolean) => {
      toolInstance.SetClearToolSelected(selected);
    },
    GetLevelConfig: () => {
      return toolInstance.GetLevelConfig();
    },
    SetLevelConfig: (data: any) => {
      toolInstance.SetLevelConfig(data);
    },
  };
  return tetrisInstance;
}

export function DestroyTetris() {}
