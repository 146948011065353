import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import _ from 'lodash';
import { ConfigContext } from './Config';
import { Gems } from './Utils';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },
  button: {
    fontSize: '15px',
    margin: 'auto',
    padding: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: '50px',
  },
  image: {
    width: '20px',
    marginRight: '10px',
  },
}));

export default function LevelConfigColors() {
  const config = useContext(ConfigContext);
  const [gemInput, setGemInput] = useState<Array<string>>(Gems.map(() => ''));
  const classes = useStyles();
  useEffect(() => {
    config.ScenarioGetter = () => {
      return gemInput;
    };
    config.ScenarioSetter = (data: string[]) => {
      setGemInput(data);
    };
  });
  return (
    <div className={classes.buttons}>
      {Gems.map((value, index) => (
        <div key={index} className={classes.button}>
          <img src={Gems[index]} className={classes.image} alt={'GEM'} />
          <TextField
            className={classes.input}
            value={gemInput[index] || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let newInput = [...gemInput];
              newInput[index] = event.target.value;
              setGemInput(newInput);
            }}
            label={'gem' + (index + 1)}
            size="small"
          />
        </div>
      ))}
    </div>
  );
}
