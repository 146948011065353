import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as State from './state';
import { Card as CardType } from '../../store/cards/types';
import { PopulatedLayer } from '../../store/selectorTypes';
import { Props } from './';
import { CardContainer } from './styles';
import Card from './Card';

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    height: '100%',
    width: '100%',
  },
  inner: {
    position: 'relative',
    margin: '0 auto',
  },
}));

export const MainView: React.FC<Props> = ({ cards, setCard, setSelectedCard, isSelected, addSelectedCard }: Props) => {
  const classes = useStyles();
  const divRef = useRef<HTMLDivElement | null>(null);
  const innerDivRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    State.SetAreaDivReference(divRef.current, innerDivRef.current);
  }, [divRef]);
  return (
    <div className={classes.root} ref={divRef}>
      <div className={classes.inner} ref={innerDivRef}>
        {cards.map((layer: PopulatedLayer, i) => (
          <CardContainer opacity={(i + 1) / cards.length} interactive={i === cards.length - 1} key={layer.id}>
            {layer.cards.map((card: CardType) => (
              <Card
                {...card}
                setCard={setCard}
                setSelected={setSelectedCard}
                isSelected={isSelected}
                addSelected={addSelectedCard}
                key={card.id}
              />
            ))}
          </CardContainer>
        ))}
      </div>
    </div>
  );
};

export default MainView;
