import React, { useRef, useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as _ from 'lodash';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfigContext } from './Config';

const useStyles = makeStyles((theme) => ({
  toolsPanel: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '5px',
    backgroundColor: theme.palette.grey[300],
  },
  input: {
    display: 'flex',
    width: '50px',
    margin: '5px',
  },
  deleteDiv: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

export interface LevelConfigFiguresItemInterface {
  from: number;
  to: number;
  moveNumber: number;
  idKey: number;
}

function LevelConfigFiguresItem(Props: {
  config: LevelConfigFiguresItemInterface;
  onDeleteCallback: (key: number) => void;
  onChangeCallback: (conf: LevelConfigFiguresItemInterface) => void;
}) {
  const [fromInput, setFromInput] = useState<number>(Props.config.from);
  const [toInput, setToInput] = useState<number>(Props.config.to);
  const [nmoveInput, setNmoveInput] = useState<number>(Props.config.moveNumber);
  useEffect(() => {
    setFromInput(Props.config.from);
    setToInput(Props.config.to);
    setNmoveInput(Props.config.moveNumber);
  }, [Props.config]);
  const classes = useStyles();
  return (
    <form noValidate autoComplete="off">
      <Card variant="outlined" className={classes.item}>
        <TextField
          type="number"
          className={classes.input}
          value={fromInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            Props.onChangeCallback({
              from: parseInt(event.target.value),
              to: toInput,
              moveNumber: nmoveInput,
              idKey: Props.config.idKey,
            });
            setFromInput(parseInt(event.target.value));
          }}
          label="from"
          size="small"
        />
        <TextField
          type="number"
          className={classes.input}
          value={toInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            Props.onChangeCallback({
              from: fromInput,
              to: parseInt(event.target.value),
              moveNumber: nmoveInput,
              idKey: Props.config.idKey,
            });
            setToInput(parseInt(event.target.value));
          }}
          label="to"
          size="small"
        />
        <TextField
          type="number"
          className={classes.input}
          value={nmoveInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            Props.onChangeCallback({
              from: fromInput,
              to: toInput,
              moveNumber: parseInt(event.target.value),
              idKey: Props.config.idKey,
            });
            setNmoveInput(parseInt(event.target.value));
          }}
          label="#move"
          size="small"
        />
        <div className={classes.deleteDiv}>
          <Button
            aria-label="delete"
            onClick={() => {
              Props.onDeleteCallback(Props.config.idKey);
            }}
          >
            <DeleteIcon fontSize="small" />
          </Button>
        </div>
      </Card>
    </form>
  );
}

export default function LevelConfigFigures() {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const lastItemId = useRef(0);
  const [items, setItems] = useState<Array<LevelConfigFiguresItemInterface>>([]);
  useEffect(() => {
    config.FiguresGetter = () => {
      return items;
    };
    config.FiguresSetter = (data: LevelConfigFiguresItemInterface[]) => {
      lastItemId.current = data.length;
      setItems(data.map((v, index) => ({ from: v.from, to: v.to, moveNumber: v.moveNumber, idKey: index })));
    };
  });
  const handleClick = function () {
    setItems([...items, { from: 0, to: 0, moveNumber: 0, idKey: ++lastItemId.current }]);
  };
  const onDeleteCallback = function (key: number) {
    setItems(_.filter(items, (item) => item.idKey !== key));
  };
  const updateConfiguration = function (conf: LevelConfigFiguresItemInterface) {
    let newItems = [...items];
    newItems[_.findIndex(newItems, (o) => o.idKey === conf.idKey)] = conf;
    setItems(newItems);
  };
  const getItems = function () {
    let tempItems = [];
    for (let i = 0; i < items.length; i++)
      tempItems.push(
        <LevelConfigFiguresItem
          config={items[i]}
          key={items[i].idKey}
          onDeleteCallback={onDeleteCallback}
          onChangeCallback={updateConfiguration}
        />,
      );
    return tempItems;
  };
  return (
    <div>
      <div className={classes.toolsPanel}>
        <Button aria-controls="gem-menu" aria-haspopup="true" onClick={handleClick}>
          <AddOutlinedIcon />
        </Button>
      </div>
      {getItems()}
    </div>
  );
}
