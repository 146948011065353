import axios, { AxiosInstance } from 'axios';
import { Model } from './types';

export default class apiModel {
  axios: AxiosInstance;
  constructor(model: Model) {
    this.axios = axios.create({ baseURL: `https://api.bigbutton.co/${model}` });
  }

  async getLevel(level: number) {
    return (await this.axios.get(`level/${level}`)).data;
  }

  async setLevel(level: number, data: any) {
    return (await this.axios.post(`level/${level}`, { level: data })).data;
  }

  async addLevel(level: number) {
    return (await this.axios.post(`level/empty/${level}`)).data;
  }

  async deleteLevel(level: number) {
    return (await this.axios.delete(`level/${level}`)).data;
  }

  async getLevelsSize() {
    return (await this.axios.get(`levels/length`)).data;
  }

  async getAllLevels() {
    return (await this.axios.get('levels')).data;
  }
}
