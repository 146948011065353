import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AllSolitareModels, CatsVsZombies, SolitaireModel } from '../../api/types';
import { ToolState } from '../../store';
import { thunkSaveLevel } from '../../store/layers/actions';
import Controls from './Controls';

const mapStateToProps = (state: ToolState) => ({
  state: state,
  chosenLevel: state.tools.chosenLevel,
});

const getMapDispatchToProps = (model: AllSolitareModels) => {
  return { thunkSaveLevel: (level: number, data: any) => thunkSaveLevel(model, level, data) };
};

const solitaireConnector = connect(mapStateToProps, getMapDispatchToProps(SolitaireModel));
const catsVsZombiesConnector = connect(mapStateToProps, getMapDispatchToProps(CatsVsZombies));

export type Props = ConnectedProps<typeof solitaireConnector | typeof catsVsZombiesConnector>;

const ControlsWrapper = ({ model }: { model: AllSolitareModels }) => {
  let Connector;
  switch (model) {
    case SolitaireModel:
      Connector = solitaireConnector(Controls);
      break;
    case CatsVsZombies:
      Connector = catsVsZombiesConnector(Controls);
      break;
    default:
      Connector = solitaireConnector(Controls);
      break;
  }
  return <Connector></Connector>;
};

export default ControlsWrapper;
