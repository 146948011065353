import { clamp, findIndex } from 'lodash';
import { CardLayerConfig, LayersMap, Layer } from '../LayersList/types';
import { CardType } from './types';

let areaRef: HTMLDivElement | null = null;
let innerAreaRef: HTMLDivElement | null = null;
export function SetAreaDivReference(mainRef: HTMLDivElement | null, innerRef: HTMLDivElement | null) {
  if (mainRef) areaRef = mainRef;
  if (innerRef) innerAreaRef = innerRef;
  onResize();
}
let TopLeftCorner = { x: 0, y: 0 };
const W = 6;
const H = 5;
export const middle = { x: W / 2, y: H / 2 };
let gridDensity = 0.5;
let cardWidth = 40;
let cardHeight = 60;
let segmentW = cardWidth * gridDensity;
let segmentH = cardHeight * gridDensity;
export function getCardSizes(): [number, number] {
  return [cardWidth, cardHeight];
}

export let ignoreResetSelected = false;
export function setIgnoreSelected(ignore: boolean) {
  ignoreResetSelected = ignore;
}

export function setGridDensity(density: string) {
  const value = parseFloat(density);
  if (value) {
    gridDensity = value;
    segmentW = cardWidth * gridDensity;
    segmentH = cardHeight * gridDensity;
    updateStyles();
  }
}

function debounce(cb: () => void) {
  var timer: number;
  return function (event: UIEvent) {
    if (timer) clearTimeout(timer);
    timer = window.setTimeout(cb, 200, event);
  };
}

function updateStyles() {
  if (innerAreaRef) {
    innerAreaRef.style.width = `${(W + 1) * cardWidth + 1}px`;
    innerAreaRef.style.height = `${(H + 1) * cardHeight + 1}px`;
    const densityGridPercents = Math.floor(gridDensity * 100);
    innerAreaRef.style.backgroundImage = `
      repeating-linear-gradient(#aaa 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #aaa 0 1px, transparent 1px 100%),
      repeating-linear-gradient(#ddd 0 1px, transparent 1px ${densityGridPercents}%),
      repeating-linear-gradient(90deg, #ddd 0 1px, transparent 1px ${densityGridPercents}%)
    `;
    innerAreaRef.style.backgroundSize = `${cardWidth}px ${cardHeight}px`;
    let rect = innerAreaRef.getBoundingClientRect();
    TopLeftCorner = { x: rect.left, y: rect.top };
  }
}

export function onResize() {
  if (areaRef) {
    let rect = areaRef.getBoundingClientRect();
    cardHeight = Math.floor(rect.height / 6);
    cardWidth = Math.round(cardHeight * 0.6);
    segmentW = cardWidth * gridDensity;
    segmentH = cardHeight * gridDensity;
    updateStyles();
  }
}
window.onresize = debounce(onResize);

export function GetGridPosition(x: number, y: number) {
  return {
    x: clamp(gridDensity * Math.round((x - TopLeftCorner.x) / segmentW), 0, W) - middle.x,
    y: clamp(gridDensity * Math.round((y - TopLeftCorner.y) / segmentH), 0, H) - middle.y,
  };
}

export function GetSelectionGridRect(x: number, y: number, w: number, h: number) {
  return {
    x: (x - TopLeftCorner.x) / cardWidth - 1 - middle.x,
    y: (y - TopLeftCorner.y) / cardHeight - 1 - middle.y,
    w: (x + w - TopLeftCorner.x) / cardWidth - middle.x,
    h: (y + h - TopLeftCorner.y) / cardHeight - middle.y,
  };
}

export function GetGridMovedPosition(x: number, y: number, dx: number, dy: number) {
  return {
    x: clamp(x + Math.round(dx / segmentW) * gridDensity, -middle.x, middle.x) - x,
    y: clamp(y + Math.round(dy / segmentH) * gridDensity, -middle.y, middle.y) - y,
  };
}

export function GetRealPosition(x: number, y: number, initial: [number, number] = [0, 0]) {
  return {
    x: TopLeftCorner.x + (x + middle.x) * cardWidth - initial[0],
    y: TopLeftCorner.y + (y + middle.y) * cardHeight - initial[1],
  };
}
export function GetRelativePosition(x: number, y: number, normalize = true) {
  return {
    x: (x + +normalize * middle.x) * cardWidth,
    y: (y + +normalize * middle.y) * cardHeight,
  };
}

let CardLayers: LayersMap = {};
let chosenCardId = 0;
let lastCardId = 0;
export function AddNewLayer(conf: CardLayerConfig) {
  const id = conf.id;
  if (CardLayers[id]) {
    throw `card layer ${id} already exists`;
  }
  CardLayers[id] = { config: { ...conf }, cards: [] };
}

export function GetEditLayer() {
  const key = Object.keys(CardLayers).find((key) => CardLayers[key].config.editChecked === true);
  return key ? CardLayers[key] : null;
}

let onCardsChangeCallback: ((cards: Array<CardType>) => void) | null = null;
export function SetOnCardsChange(cb: (cards: Array<CardType>) => void) {
  onCardsChangeCallback = cb;
}

export function GetVisibleCards() {
  const editLayer = GetEditLayer();
  return editLayer
    ? [...editLayer.cards]
    : Object.keys(CardLayers)
        .filter((key: string) => CardLayers[key].config.showChecked)
        .reduce<Array<CardType>>((acc: Array<CardType>, key) => {
          acc = acc.concat(CardLayers[key].cards);
          return acc;
        }, []);
}

export function SetLayerConfig(conf: CardLayerConfig) {
  CardLayers[conf.id].config = { ...conf };
  console.log(conf);
  onCardsChangeCallback && onCardsChangeCallback(GetVisibleCards());
}

export function AddNewCard(x: number, y: number) {
  const editLayer = GetEditLayer();
  editLayer && editLayer.cards.push({ reactId: lastCardId++ + '', id: chosenCardId, x: x, y: y, rotation: 0 });
  onCardsChangeCallback && onCardsChangeCallback(GetVisibleCards());
  console.log(CardLayers, editLayer, x, y);
}
