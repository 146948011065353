import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Model } from '../../api/types';
import { useStyles } from './styles';
import apiModel from '../../api';
import ReactJson, { CollapsedFieldProps } from 'react-json-view';

const Editor: React.FC = () => {
  const classes = useStyles();
  const { model }: { model: Model } = useParams();
  const [modelInstance, setModelInstance] = useState<apiModel | null>(null);
  const [config, setConfig] = useState({});
  useEffect(() => {
    if (modelInstance === null) return;
    modelInstance.getAllLevels().then((data) => {
      console.log(data);
      setConfig(data);
    });
  }, [modelInstance]);
  useEffect(() => {
    setModelInstance(new apiModel(model));
  }, [model]);
  const shouldCollapse = (field: CollapsedFieldProps) => {
    switch (field.type) {
      case 'array':
        return true;
      default:
        return false;
    }
  };
  return (
    <div className={classes.root}>
      <ReactJson shouldCollapse={shouldCollapse} src={config} theme="monokai" displayDataTypes={false} />
    </div>
  );
};

export default Editor;
