import { Card } from './cards/types';
import { ToolState } from './';
import _ from 'lodash';
import { Layer, LayersState } from './layers/types';
import { PopulatedLayer } from './selectorTypes';
import { cardConfSeparator } from './helper';

let numOnionSkins = 3;

export function getVisibleCards(state: ToolState): Array<PopulatedLayer> {
  const layer = state.layers.layers.find((layer) => layer.editChecked === true);
  if (layer) {
    const editLayerIndex = state.layers.layers.indexOf(layer);
    const cards = [{ id: layer.id, cards: layer.cards.map((id) => state.layers.cards[id]) }];
    for (let i = 1; i <= numOnionSkins; i++) {
      const nextIndex = editLayerIndex - i;
      if (nextIndex < 0) break;
      const nextLayer = state.layers.layers[nextIndex];
      if (nextLayer.showChecked)
        cards.push({ id: nextLayer.id, cards: nextLayer.cards.map((id) => state.layers.cards[id]) });
    }
    return cards.reverse();
  }
  const cards: Array<Card> = [];
  state.layers.layers.forEach((layer) => {
    if (layer.showChecked) {
      cards.push(...layer.cards.map((id) => state.layers.cards[id]));
    }
  });
  return [{ id: 'shown', cards }];
}

export function getSelectedLayer(state: LayersState): Layer | undefined {
  return state.layers.find((layer) => layer.editChecked);
}

export function getSelectedCards(state: ToolState): Array<Card> {
  return state.layers.selected.map((id) => state.layers.cards[id]);
}

export function getSelectedCardsCommonProps(state: ToolState): { rotation: number; kind: string; id: string } {
  const cards = getSelectedCards(state);
  if (cards.length === 0) return { rotation: state.tools.rotation, id: state.tools.cardId, kind: state.tools.cardKind };
  let commonRotation = cards[0].rotation;
  let commonId = cards[0].p1 || '';
  let commonKind = cards[0].p2 || '';
  cards.forEach((card) => {
    if (commonRotation !== card.rotation) commonRotation = 0;
    if (commonId !== card.p1) commonId = '';
    if (commonKind !== card.p2) commonKind = '';
  });
  return { rotation: commonRotation, kind: commonKind, id: commonId };
}

export function getCardConfigs(cardConf: string): { id: string; kind: string } {
  const split = cardConf.split(cardConfSeparator);
  return { id: split[0] || '', kind: split[1] || '' };
}

export function getJsonData(layersState: LayersState): object {
  const layers = layersState.layers;
  const data = layers.map((layer) => {
    layer.cards.sort((a, b) => {
      const deltaY = layersState.cards[a].y - layersState.cards[b].y;
      return deltaY === 0 ? +(layersState.cards[a].x - layersState.cards[b].x > 0) * 2 - 1 : +(deltaY > 0) * 2 - 1;
    });
    return layer.cards.map((id) => ({ ...layersState.cards[id], id: undefined }));
  });
  return { layers: data, extraCards: layersState.extraCards, extra: layersState.extra || undefined };
}
