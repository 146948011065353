import React, { useContext, useEffect } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigContext } from './Config';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    padding: '0px',
    fontSize: '15px',
    margin: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function TablePaginationDemo() {
  const configContext = useContext(ConfigContext);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [numLevels, setNumLevels] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    configContext.OnLevelAdd = () => {
      setNumLevels(configContext.LevelsLength);
    };
  }, [configContext]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    configContext.LevelNumberSetter(parseInt(event.currentTarget.id));
    console.log(event.currentTarget.id);
  };

  return (
    <div>
      <div className={classes.buttons}>
        {(() => {
          let btns = [];
          let rows = Math.min(numLevels - page * rowsPerPage, rowsPerPage);
          for (let i = 0; i < rows; i++)
            btns.push(
              <Button
                className={classes.button}
                key={i + page * rowsPerPage}
                id={(i + page * rowsPerPage).toString()}
                onClick={handleButtonClick}
                variant="outlined"
              >
                {i + page * rowsPerPage + 1}
              </Button>,
            );
          return btns;
        })()}
      </div>
      <div className={classes.pagination}>
        <TablePagination
          component="div"
          count={numLevels}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
