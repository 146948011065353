import React, { useEffect, useState } from 'react';
import MovingCard from './MovingCard';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { setGridDensity, setIgnoreSelected } from '../MainView/state';
import { Card } from '../../store/cards/types';
import { getCardConfigs } from '../../store/selectors';
import { Props } from './';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },
  textFiled: {
    maxWidth: '100px',
  },
  densityField: {
    maxWidth: '50px',
  },
  itemGrid: {
    '& > *': {
      margin: '5px',
    },
  },
  slider: {
    maxWidth: '250px',
  },
}));

export const CardTool: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const handleChange = (event: any, newValue: number | number[]) => {
    const rotation = newValue as number;
    if (props.selectedCards.length > 0) {
      props.setCards(
        props.selectedCards.reduce<Record<string, Card>>((obj, card) => {
          obj[card.id] = { ...card, rotation };
          return obj;
        }, {}),
      );
    } else {
      props.setRotation(rotation);
    }
  };
  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.selectedCards.length > 0) {
      props.setCards(
        props.selectedCards.reduce<Record<string, Card>>((obj, card) => {
          obj[card.id] = { ...card, p1: event.target.value };
          return obj;
        }, {}),
      );
    } else {
      props.setId(event.target.value);
    }
  };
  const handleKindChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.selectedCards.length > 0) {
      props.setCards(
        props.selectedCards.reduce<Record<string, Card>>((obj, card) => {
          obj[card.id] = { ...card, p2: event.target.value };
          return obj;
        }, {}),
      );
    } else {
      props.setKind(event.target.value);
    }
  };
  const handleDelete = () => {
    if (props.selectedCards.length > 0) {
      props.selectedCards.forEach((card) => {
        props.removeCard(card.id);
      });
      props.setSelectedCard(null);
    }
  };
  const handleSetDensity = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setDensity(event.target.value);
    setGridDensity(event.target.value);
  };
  const handleMouseDown = () => {
    setIgnoreSelected(true);
  };
  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="space-around"
      alignItems="center"
      onMouseDown={handleMouseDown}
    >
      <MovingCard {...props} />
      <Grid item className={classes.itemGrid}>
        <TextField
          id="card-type-id"
          label="Card"
          className={classes.textFiled}
          value={props.commonProps.id}
          onChange={handleIdChange}
        />
        <TextField
          id="card-value-id"
          label="Kind"
          className={classes.textFiled}
          value={props.commonProps.kind}
          onChange={handleKindChange}
        />
      </Grid>
      <Slider
        onChange={handleChange}
        value={props.commonProps.rotation}
        className={classes.slider}
        defaultValue={0}
        min={-180}
        max={180}
        aria-labelledby="discrete-slider-always"
        step={10}
        marks
        valueLabelDisplay="auto"
      />
      <TextField
        id="denisty-id"
        label="Density"
        type="number"
        className={classes.densityField}
        value={props.tools.density}
        onChange={handleSetDensity}
      />
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <b>MirrorX</b>
          <Button variant="outlined" onClick={props.mirrorHorizontal}>
            Pos
          </Button>
          <Button variant="outlined" onClick={props.mirrorHorizontalFull}>
            Full
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <b>MirrorY</b>
          <Button variant="outlined" onClick={props.mirrorVertical}>
            Pos
          </Button>
          <Button variant="outlined" onClick={props.mirrorVerticalFull}>
            Full
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <b>MirrorXY</b>
          <Button variant="outlined" onClick={props.mirrorBoth}>
            Pos
          </Button>
          <Button variant="outlined" onClick={props.mirrorBothFull}>
            Full
          </Button>
        </Grid>
      </Grid>

      <Button onClick={handleDelete}>Delete</Button>
    </Grid>
  );
};

export default CardTool;
