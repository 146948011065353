import { combineReducers } from 'redux';
import { layerReducer } from './layers/reducer';
import { toolsReducer } from './tools/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  layers: layerReducer,
  tools: toolsReducer,
});

export type ToolState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
