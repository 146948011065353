import { connect, ConnectedProps } from 'react-redux';
import { setSelectedCard, setEmptyState } from '../store/layers/actions';
import CardTool from './CardTool';

const mapDispatchToProps = {
  setSelectedCard: (id: string | null) => setSelectedCard(id),
  setEmptyState,
};

const connector = connect(undefined, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(CardTool);
