import * as PIXI from 'pixi.js';
import spritesheetData from './i/spritesheet.json';
import spritesheetImage from './i/spritesheet.png';

const boardSize = 9;
let gridCellSize: number;
let spritesheet: PIXI.Spritesheet;

const clamp = function (x: number, min: number, max: number) {
  return Math.min(Math.max(x, min), max);
};

interface confInterface {
  hasGlass: boolean;
  hasBoulder: boolean;
  hasGem: boolean;
}

export class tool {
  grid: cellObject[][];
  container: PIXI.Container;
  currentGemStyle: number = 1;
  currentConf: confInterface = { hasGlass: false, hasBoulder: false, hasGem: true };
  isPressed: boolean = false;
  clearToolSelected: boolean = false;
  lastCell: [number, number];
  constructor(app: PIXI.Application) {
    this.container = new PIXI.Container();
    this.lastCell = [-1, -1];
    gridCellSize = app.view.width / (boardSize + 1);
    console.log('size', gridCellSize);
    this.grid = new Array(boardSize);
    for (let i = 0; i < boardSize; i++) {
      this.grid[i] = new Array(boardSize);
    }
    const onLoad = () => {
      this.buildBackground();
      this.container.interactive = true;
      this.container.on('pointermove', (e: PIXI.InteractionEvent) => {
        if (!this.isPressed) return;
        let pos = e.data.getLocalPosition(this.container);
        this.handlePointerInput(pos);
      });
      this.container.on('pointerdown', (e: PIXI.InteractionEvent) => {
        let pos = e.data.getLocalPosition(this.container);
        this.handlePointerInput(pos);
        this.isPressed = true;
      });
      this.container.on('pointerup', (e: PIXI.InteractionEvent) => {
        this.isPressed = false;
        this.lastCell = [-1, -1];
      });
      this.container.on('pointerupoutside', () => {
        this.isPressed = false;
        this.lastCell = [-1, -1];
      });
    };
    if (PIXI.Loader.shared.resources['sheet']) {
      console.log('HERE');
      onLoad();
    } else {
      PIXI.Loader.shared.add('sheet', spritesheetImage).load((loader, resources) => {
        if (resources['sheet']) {
          let sheet = new PIXI.Spritesheet(resources['sheet'].texture, spritesheetData);
          sheet.parse(() => {
            spritesheet = sheet;
          });
          onLoad();
        }
      });
    }
    app.stage.addChild(this.container);
    this.container.position.set(gridCellSize * 0.5, gridCellSize * 0.5);
  }

  handlePointerInput(pos: PIXI.Point) {
    let x = clamp(Math.floor(pos.x / gridCellSize), 0, boardSize - 1);
    let y = clamp(Math.floor(pos.y / gridCellSize), 0, boardSize - 1);
    if (this.lastCell[0] === x && this.lastCell[1] === y) return;
    this.lastCell = [x, y];
    if (this.grid[x][y]) this.grid[x][y].Destroy();
    if (this.clearToolSelected) {
      this.grid[x][y] = new cellObject(
        this.container,
        new PIXI.Point(x * gridCellSize, y * gridCellSize),
        1,
        false,
        false,
        false,
      );
      return;
    }
    this.grid[x][y] = new cellObject(
      this.container,
      new PIXI.Point(x * gridCellSize, y * gridCellSize),
      this.currentGemStyle,
      this.currentConf.hasGem,
      this.currentConf.hasGlass,
      this.currentConf.hasBoulder,
    );
  }

  buildBackground() {
    for (let i = 0; i < boardSize; i++) {
      for (let j = 0; j < boardSize; j++) {
        let cellSprite = new PIXI.Sprite(spritesheet.textures['cell.png']);
        this.container.addChild(cellSprite);
        cellSprite.width = cellSprite.height = gridCellSize;
        cellSprite.position.set(i * gridCellSize, j * gridCellSize);
      }
    }
  }

  SetGemStyle(gemStyle: number) {
    this.currentGemStyle = gemStyle + 1;
  }

  SetToolsConf(conf: confInterface) {
    this.currentConf = conf;
  }

  SetClearToolSelected(selected: boolean) {
    this.clearToolSelected = selected;
  }

  GetLevelConfig() {
    let conf: Array<Array<string | number> | number> = [];
    let emptyY = 0;
    for (let y = 0; y < boardSize; y++) {
      let row: Array<string | number> = [];
      let emptyX = 0;
      for (let x = 0; x < boardSize; x++) {
        let cell: cellObject = this.grid[x][y];
        if (!cell || cell.isEmpty) {
          emptyX++;
          continue;
        }
        if (emptyX > 0) {
          row.push(emptyX);
          emptyX = 0;
        }
        row.push(cell.hash);
      }
      if (row.length > 0) {
        if (emptyY > 0) {
          conf.push(emptyY);
          emptyY = 0;
        }
        conf.push(row);
      } else emptyY++;
    }
    return conf;
  }

  SetLevelConfig(data: Array<Array<string | number> | number>) {
    this.lastCell = [-1, -1];
    for (let x = 0; x < boardSize; x++) {
      for (let y = 0; y < boardSize; y++) {
        if (this.grid[x][y]) this.grid[x][y].Destroy();
        this.grid[x][y] = new cellObject(
          this.container,
          new PIXI.Point(x * gridCellSize, y * gridCellSize),
          1,
          false,
          false,
          false,
        );
      }
    }
    if (!Array.isArray(data)) return;

    let y = 0;
    for (let i = 0; i < data.length; i++) {
      let x = 0;
      let rowData = data[i];
      if (typeof rowData === 'number') {
        y += +rowData - 1;
        continue;
      }
      for (let j = 0; j < rowData.length; j++) {
        let tempData = rowData[j];
        if (typeof tempData === 'number') x += +tempData - 1;
        else {
          let gem: number = NaN;
          let boulder: boolean = false;
          let glass: boolean = false;
          let color = parseInt(tempData[0]);
          if (!isNaN(color)) gem = color;
          boulder = tempData.includes('B');
          glass = tempData.includes('G');
          this.grid[x + j][y + i] = new cellObject(
            this.container,
            new PIXI.Point((x + j) * gridCellSize, (y + i) * gridCellSize),
            gem,
            !isNaN(gem),
            glass,
            boulder,
          );
        }
      }
    }
  }

  Destroy() {
    console.log('destroy');
  }
}

class cellObject {
  hash: string;
  container: PIXI.Container;
  isEmpty: boolean;
  constructor(
    parent: PIXI.Container,
    position: PIXI.Point,
    gemStyle: number,
    hasGem: boolean,
    hasGlass: boolean = false,
    hasBoulder: boolean = false,
  ) {
    this.container = new PIXI.Container();
    parent.addChild(this.container);
    this.isEmpty = !(hasGem || hasGlass || hasBoulder);
    this.hash = '';
    if (hasGem) {
      let gem = new PIXI.Sprite(spritesheet.textures['gem' + gemStyle + '.png']);
      gem.width = gem.height = gridCellSize * 0.6;
      gem.position.set(gridCellSize * 0.2);
      this.container.addChild(gem);
      this.hash += gemStyle;
    }
    if (hasGlass) {
      let glass = new PIXI.Sprite(spritesheet.textures['cell-glass.png']);
      glass.width = glass.height = gridCellSize;
      this.container.addChildAt(glass, 0);
      this.hash += 'G';
    }
    if (hasBoulder) {
      let boulder = new PIXI.Sprite(spritesheet.textures['cell-stone.png']);
      boulder.width = boulder.height = gridCellSize;
      this.container.addChildAt(boulder, 0);
      this.hash += 'B';
    }
    this.container.position = position;
  }

  Destroy() {
    this.container.destroy({ children: true });
  }
}
