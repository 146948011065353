import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LevelTool from './LevelTool';
import LevelTasks from './LevelTasks';
import LevelConfigFigures from './LevelConfigFigures';
import LevelConfigColors from './LevelConfigColors';
import LevelConfigStars from './LevelConfigStars';
import SaveButton from './SaveButton';
import LevelsGrid from './LevelsGrid';
import { ConfigContext } from './Config';
import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '520px',
  },
  bottomPapers: {
    height: '300px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  levels: {
    padding: theme.spacing(2),
    height: '50%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  colorsConfig: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  save: {
    height: '100%',
  },
}));

export default function MdsTool() {
  const classes = useStyles();

  return (
    <ConfigContext.Provider
      value={{
        FiguresGetter: () => [],
        ScenarioGetter: () => [],
        TasksGetter: () => [],
        LevelGetter: () => [],
        FiguresSetter: () => {},
        StarsGetter: () => [1, 2],
        ScenarioSetter: () => {},
        TasksSetter: () => {},
        LevelSetter: () => {},
        StarsSetter: () => {},
        LevelNumberSetter: () => {},
        OnLevelAdd: () => {},
        LevelsLength: 0,
        CurrentLevel: 0,
      }}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Paper className={classes.paper}>
              <LevelTool></LevelTool>
            </Paper>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Paper className={classes.paper}>
              <LevelTasks></LevelTasks>
            </Paper>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Paper className={classes.paper}>
              <LevelConfigFigures />
            </Paper>
          </Grid>
          <Grid item xs={10} className={classes.bottomPapers}>
            <Grid container spacing={3}>
              <Grid item xs={10} lg={10}>
                <Paper className={classes.colorsConfig}>
                  <LevelConfigColors />
                </Paper>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Paper className={classes.colorsConfig}>
                  <LevelConfigStars />
                </Paper>
              </Grid>
            </Grid>
            <Paper className={classes.levels}>
              <LevelsGrid />
            </Paper>
          </Grid>
          <Grid item xs={2} className={classes.bottomPapers}>
            <Paper className={classes.save}>
              <SaveButton />
            </Paper>
          </Grid>
        </Grid>
        {/* <Box pt={4}>
          <Copyright />
        </Box> */}
      </Container>
    </ConfigContext.Provider>
  );
}
