import React from 'react';
import { Props } from './';
import { GameConfigsWrap } from './styles';
import TextField from '@material-ui/core/TextField';

export const GameConfigs: React.FC<Props> = ({ extraCards, setExtraCards, setExtra, extra }: Props) => {
  const handleExtraChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtra(event.target.value);
  };
  const handleExtraCardsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExtraCards(parseInt(event.target.value) || 0);
  };
  return (
    <GameConfigsWrap>
      <TextField
        id="outlined-basic"
        label="Extra Cards"
        variant="outlined"
        type="number"
        onChange={handleExtraCardsChange}
        value={extraCards}
      />
      <TextField
        id="outlined-basic"
        label="Extra"
        variant="outlined"
        onChange={handleExtraChange}
        defaultValue={undefined}
        value={extra || ''}
      />
    </GameConfigsWrap>
  );
};

export default GameConfigs;
