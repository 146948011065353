import { connect, ConnectedProps } from 'react-redux';
import { ADD_LAYER, Layer } from '../../store/layers/types';
import { ToolState } from '../../store';
import { addLayer, setLayer, removeLayer, setLayersOrder } from '../../store/layers/actions';
import Layers from './Layers';

const mapStateToProps = (state: ToolState) => ({
  state: state.layers,
});

const mapDispatchToProps = {
  addLayer: () => addLayer(),
  setLayer: (conf: Layer) => setLayer(conf),
  removeLayer: (id: string) => removeLayer(id),
  setLayersOrder: (order: number[]) => setLayersOrder(order),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(Layers);
