import {
  SET_ID,
  SET_ROTATION,
  SET_CHOSEN_LEVEL,
  ToolsAction,
  ToolsState,
  SET_NUMBER_LEVELS,
  SET_DENSITY,
  SET_KIND,
} from './types';

const initialState: ToolsState = {
  cardId: '',
  cardKind: '',
  rotation: 0,
  chosenLevel: null,
  numberLevels: 0,
  density: '0.5',
};

export function toolsReducer(state = initialState, action: ToolsAction): ToolsState {
  switch (action.type) {
    case SET_ROTATION:
      return { ...state, rotation: action.rotation };
    case SET_ID:
      return { ...state, cardId: action.idConf };
    case SET_KIND:
      return { ...state, cardKind: action.kindConf };
    case SET_CHOSEN_LEVEL:
      return { ...state, chosenLevel: action.level };
    case SET_NUMBER_LEVELS: {
      return { ...state, numberLevels: action.size };
    }
    case SET_DENSITY: {
      return { ...state, density: action.density };
    }
    default:
      return state;
  }
}
