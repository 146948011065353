import { connect, ConnectedProps } from 'react-redux';
import { Card } from '../../store/cards/types';
import { ToolState } from '../../store';
import { addCard, setCard, removeCard, setSelectedCard, setCards, mirrorCards } from '../../store/layers/actions';
import { getVisibleCards, getSelectedCards, getSelectedCardsCommonProps } from '../../store/selectors';
import ToolsArea from './ToolsArea';
import { setId, setKind, setRotation, setDensity } from '../../store/tools/actions';

const mapStateToProps = (state: ToolState) => ({
  tools: state.tools,
  commonProps: getSelectedCardsCommonProps(state),
  selectedCards: getSelectedCards(state),
});

const mapDispatchToProps = {
  mirrorHorizontal: () => mirrorCards(true, false, false),
  mirrorHorizontalFull: () => mirrorCards(true, false, true),
  mirrorVertical: () => mirrorCards(false, true, false),
  mirrorVerticalFull: () => mirrorCards(false, true, true),
  mirrorBoth: () => mirrorCards(true, true, false),
  mirrorBothFull: () => mirrorCards(true, true, true),
  setRotation: (rotation: number) => setRotation(rotation),
  setId: (id: string) => setId(id),
  setKind: (kind: string) => setKind(kind),
  addCard: (card: Card) => addCard(card),
  setCard: (id: string, card: Card) => setCard(id, card),
  setCards: (cards: Record<string, Card>) => setCards(cards),
  removeCard: (id: string) => removeCard(id),
  setSelectedCard: (id: string | null) => setSelectedCard(id),
  setDensity: (denisty: string) => setDensity(denisty),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(ToolsArea);
