import { connect, ConnectedProps } from 'react-redux';
import { selectInRect } from '../../store/layers/actions';
import Selection from './Selection';

const mapDispatchToProps = {
  selectInRect,
};

const connector = connect(undefined, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(Selection);
