import React, { useEffect, useState } from 'react';
import modelApi from '../../api/';
import { SolitaireModel } from '../../api/types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TablePagination from '@material-ui/core/TablePagination';
import { Props } from './';

const SolitareApiModel = new modelApi(SolitaireModel);

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    padding: '0px',
    fontSize: '15px',
    margin: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const View: React.FC<Props> = (props: Props) => {
  const { thunkGetNumberLevels, numberLevels } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    thunkGetNumberLevels();
  }, [thunkGetNumberLevels]);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    const index = parseInt(event.currentTarget.id);
    props.setChosenLevel(index);
    props.thunkGetLevel(index);
  };
  const handleAddLevel = () => {
    if (props.chosenLevel !== null) {
      props.thunkAddLevel(props.chosenLevel + 1);
      return;
    }
    props.thunkAddLevel(0);
  };
  const handleDeleteLevel = () => {
    if (props.chosenLevel !== null) props.thunkDeleteLevel(props.chosenLevel);
  };
  return (
    <div className={classes.root}>
      <ButtonGroup size="small" aria-label="levels tools">
        <Button color="primary" onClick={handleAddLevel}>
          Add
        </Button>
        <Button onClick={props.copyLayout}>Copy</Button>
        <Button onClick={props.pasteLayout}>Paste</Button>
        <Button color="secondary" onClick={handleDeleteLevel}>
          Del
        </Button>
      </ButtonGroup>
      <div className={classes.buttons}>
        {(() => {
          let btns = [];
          let rows = Math.min(numberLevels - page * rowsPerPage, rowsPerPage);
          for (let i = 0; i < rows; i++) {
            const index = i + page * rowsPerPage;
            btns.push(
              <Button
                className={classes.button}
                key={index}
                id={index.toString()}
                onClick={handleButtonClick}
                variant={props.chosenLevel === index ? 'contained' : 'outlined'}
              >
                {index + 1}
              </Button>,
            );
          }
          return btns;
        })()}
      </div>
      <div className={classes.pagination}>
        <TablePagination
          component="div"
          count={props.numberLevels}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default View;
