import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import MdsTool from './MdsTool/MdsTool';
import CardTool from './CardTool';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useStyles } from './DashboardStyles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewCarouselSharpIcon from '@material-ui/icons/ViewCarouselSharp';
import PetsIcon from '@material-ui/icons/Pets';
import GameSubMenu from './components/GameSubMenu';
import Editor from './components/Editor';
import { CatsVsZombies, MdsModel, SolitaireModel } from './api/types';

export default function Main() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Tool
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <GameSubMenu IconElement={<ViewCarouselSharpIcon />} prefix="cardtool" model={SolitaireModel} />
          <GameSubMenu IconElement={<PetsIcon />} prefix="catsVsZombies" model={CatsVsZombies} />
          <GameSubMenu IconElement={<DashboardIcon />} prefix="mdstool" model={MdsModel} />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route path="/mdstool">
            <MdsTool />
          </Route>
          <Route path="/cardtool">
            <CardTool model={SolitaireModel} />
          </Route>
          <Route path="/catsVsZombies">
            <CardTool model={CatsVsZombies} />
          </Route>
          <Route path="/editor/:model">
            <Editor />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
