import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import apiModel from '../../api/';
import { SolitaireModel, CatsVsZombies, AllSolitareModels } from '../../api/types';
import {
  LayersState,
  LayerActionTypes,
  ADD_LAYER,
  SET_LAYER,
  Layer,
  REMOVE_LAYER,
  REMOVE_CARD,
  ADD_CARD,
  SET_CARD,
  SET_SELECTED_CARD,
  ADD_SELECTED_CARD,
  SET_STATE,
  SET_ORDER,
  SET_CARDS,
  SET_EXTRA_CARDS,
  MIRROR_CARDS,
  SELECT_IN_RECT,
  LAYOUT_COPY,
  LAYOUT_PASTE,
  SET_EXTRA,
} from './types';
import { Card } from '../cards/types';
import { setNumberLevels, setChosenLevel } from '../tools/actions';
import { getID } from '../helper';

const ApiModels: { [key in AllSolitareModels]: apiModel } = {
  [SolitaireModel]: new apiModel(SolitaireModel),
  [CatsVsZombies]: new apiModel(CatsVsZombies),
};

export function addLayer(): LayerActionTypes {
  return {
    type: ADD_LAYER,
    id: getID(),
  };
}

export function setLayer(conf: Layer): LayerActionTypes {
  return {
    type: SET_LAYER,
    conf: conf,
  };
}

export function removeLayer(id: string): LayerActionTypes {
  return {
    type: REMOVE_LAYER,
    id: id,
  };
}

export function addCard(card: Card): LayerActionTypes {
  return {
    type: ADD_CARD,
    card,
  };
}

export function setCard(id: string, card: Card): LayerActionTypes {
  return {
    type: SET_CARD,
    card,
    id,
  };
}

export function setCards(cards: Record<string, Card>): LayerActionTypes {
  return {
    type: SET_CARDS,
    cards,
  };
}

export function removeCard(id: string): LayerActionTypes {
  return {
    type: REMOVE_CARD,
    id,
  };
}

export function setSelectedCard(id: string | null): LayerActionTypes {
  return {
    type: SET_SELECTED_CARD,
    id,
  };
}

export function addSelectedCard(id: string): LayerActionTypes {
  return {
    type: ADD_SELECTED_CARD,
    id,
  };
}

export function setLayersOrder(order: number[]): LayerActionTypes {
  return {
    type: SET_ORDER,
    order,
  };
}

export function setState(state: LayersState) {
  return {
    type: SET_STATE,
    state,
  };
}

export function setEmptyState() {
  return {
    type: SET_STATE,
    state: { layers: [], cards: {}, selected: [], extraCards: 0 },
  };
}

export function setExtraCards(extraCards: number) {
  return {
    type: SET_EXTRA_CARDS,
    extraCards,
  };
}

export function mirrorCards(mirrorX: boolean, mirrorY: boolean, mirrorAngle: boolean = false): LayerActionTypes {
  return {
    type: MIRROR_CARDS,
    mirrorX,
    mirrorY,
    mirrorAngle,
  };
}

export function selectInRect(x: number, y: number, w: number, h: number): LayerActionTypes {
  return {
    type: SELECT_IN_RECT,
    x,
    y,
    w,
    h,
  };
}

export function copyLayout(): LayerActionTypes {
  return {
    type: LAYOUT_COPY,
  };
}

export function pasteLayout(): LayerActionTypes {
  return {
    type: LAYOUT_PASTE,
  };
}

export function setExtra(value?: string): LayerActionTypes {
  return {
    type: SET_EXTRA,
    value,
  };
}

export const thunkGetLevel = (
  model: AllSolitareModels,
  level: number,
): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  const res = await ApiModels[model].getLevel(level);
  console.log(res);
  if (!res.cards && res.layers) {
    const state: LayersState = {
      layers: [],
      cards: {},
      selected: [],
      extraCards: res.extraCards || 0,
      extra: res.extra,
    };
    res.layers.map((layer: Card[], index: number) => {
      state.layers[index] = { id: getID(), cards: [], editChecked: false, showChecked: true };
      layer.forEach((card) => {
        const id = getID();
        card.id = id;
        state.cards[id] = card;
        state.layers[index].cards.push(id);
      });
    });
    console.log(state);
    dispatch(setState(state));
  } else {
    dispatch(setState(res));
  }
};

export const thunkAddLevel = (
  model: AllSolitareModels,
  level: number,
): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  const res = await ApiModels[model].addLevel(level);
  console.log(res.length);
  dispatch(setNumberLevels(res.length));
};

export const thunkSaveLevel = (
  model: AllSolitareModels,
  level: number,
  data: any,
): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  const res = await ApiModels[model].setLevel(level, data);
  console.log(res);
};

export const thunkDeleteLevel = (
  model: AllSolitareModels,
  level: number,
): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  const res = await ApiModels[model].deleteLevel(level);
  dispatch(setNumberLevels(parseInt(res && res.length) || 0));
  dispatch(setChosenLevel(null));
  console.log(res);
};

export const thunkGetNumberLevels = (model: AllSolitareModels): ThunkAction<void, any, unknown, AnyAction> => async (
  dispatch,
) => {
  const res = await ApiModels[model].getLevelsSize();
  console.log('getNumberLevels', model);
  dispatch(setNumberLevels(parseInt(res && res.length) || 0));
  console.log(res);
};
