import React, { useEffect } from 'react';
import ToolsArea from './ToolsArea';
import Layers from './LayersList';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ignoreResetSelected, setIgnoreSelected } from './MainView/state';
import MainView from './MainView';
import LevelsBar from './LevelsBar';
import Controls from './Controls';
import GameConfigs from './GameConfigs';
import Selection from './Selection';
import { Props } from './';
import { AllSolitareModels, SolitaireModel } from '../api/types';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: 'relative',
    userSelect: 'none',
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '520px',
  },
}));
export default function CardTool({ setSelectedCard, setEmptyState, model }: Props & { model: AllSolitareModels }) {
  const classes = useStyles();
  const handleMouseDown = () => {
    if (!ignoreResetSelected) setSelectedCard(null);
    else setIgnoreSelected(false);
  };
  useEffect(() => {
    // clear the redux state to clear the editor when the model changes
    setEmptyState();
  }, [model]);
  return (
    <div className={classes.root} onMouseDown={handleMouseDown}>
      <Selection>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Paper>
                <ToolsArea />
              </Paper>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Paper className={classes.paper}>
                <MainView />
              </Paper>
            </Grid>
            <Grid item xs={4} lg={4}>
              <Paper className={classes.paper}>
                <Layers></Layers>
              </Paper>
            </Grid>
            <Grid item xs={2} lg={2}>
              <Paper className={classes.paper}>
                <GameConfigs />
              </Paper>
            </Grid>
            <Grid item xs={10} lg={10}>
              <Paper>
                <LevelsBar model={model} />
              </Paper>
            </Grid>
            <Grid item xs={2} lg={2}>
              <Paper>
                <Controls model={model} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Selection>
    </div>
  );
}
