import { makeStyles } from '@material-ui/core';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { Props } from './';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

let boundingRect = new DOMRect();

export const Selection: React.FC<Props> = ({ selectInRect, children }: any) => {
  const classes = useStyles();
  const divRef = React.useRef<HTMLDivElement>(null);
  const [{ x, y, w, h }, setSpring] = useSpring(() => ({ x: 0, y: 0, w: 0, h: 0 }));
  const dragBind = useDrag(({ initial, movement: [dx, dy], altKey, first, cancel, canceled, last }) => {
    if (canceled) return;
    if (first) {
      if (!altKey) {
        cancel && cancel();
        setSpring({ x: 0, y: 0, w: 0, h: 0, immediate: true });
        return;
      } else {
        boundingRect = divRef.current?.getBoundingClientRect() || new DOMRect();
      }
    }
    let newX = initial[0],
      newY = initial[1];
    if (dx < 0) newX += dx;
    if (dy < 0) newY += dy;
    setSpring({
      x: newX - boundingRect.left,
      y: newY - boundingRect.top,
      immediate: true,
    });
    setSpring({ w: Math.abs(dx), h: Math.abs(dy), immediate: true });
    if (last) {
      selectInRect(newX, newY, Math.abs(dx), Math.abs(dy));
      setSpring({ x: 0, y: 0, w: 0, h: 0, immediate: true });
    }
  });
  return (
    <div {...dragBind()} ref={divRef} className={classes.root}>
      <animated.div
        style={{
          x,
          y,
          width: w,
          height: h,
          border: '1px blue solid',
          backgroundColor: 'rgba(150,150,250,0.3)',
          position: 'absolute',
          zIndex: 1,
        }}
      />
      {children}
    </div>
  );
};

export default Selection;
