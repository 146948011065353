export const SET_ROTATION = 'SET_ROTATION';
export const SET_ID = 'SET_ID';
export const SET_KIND = 'SET_KIND';
export const SET_CHOSEN_LEVEL = 'SET_CHOSEN_LEVEL';
export const SET_NUMBER_LEVELS = 'SET_NUMBER_LEVELS';
export const SET_DENSITY = 'SET_DENSITY';

export interface ToolsState {
  rotation: number;
  cardId: string;
  cardKind: string;
  chosenLevel: number | null;
  numberLevels: number;
  density: string;
}

export interface setRotationAction {
  type: typeof SET_ROTATION;
  rotation: number;
}

export interface setIdAction {
  type: typeof SET_ID;
  idConf: string;
}

export interface setKindAction {
  type: typeof SET_KIND;
  kindConf: string;
}

export interface setChosenLevel {
  type: typeof SET_CHOSEN_LEVEL;
  level: number | null;
}

export interface setNumberLevels {
  type: typeof SET_NUMBER_LEVELS;
  size: number;
}

export interface setDensity {
  type: typeof SET_DENSITY;
  density: string;
}

export type ToolsAction =
  | setRotationAction
  | setIdAction
  | setChosenLevel
  | setNumberLevels
  | setDensity
  | setKindAction;
