import React, { useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { Collapse } from '@material-ui/core';
import { useStyles } from '../../DashboardStyles';
import { GameSubMenuProps } from './types';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const GameSubMenu: React.FC<GameSubMenuProps> = ({ prefix, IconElement, model }: GameSubMenuProps) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!isOpen);
  };
  return (
    <React.Fragment>
      <ListItem onClick={handleClick} button>
        <ListItemIcon>{IconElement}</ListItemIcon>
        <ListItemText primary={model} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link to={`/${prefix}`}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Tool" />
            </ListItem>
          </Link>
          <Link to={`/editor/${model}`}>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Editor" />
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default GameSubMenu;
