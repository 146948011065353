import {
  ADD_LAYER,
  SET_LAYER,
  REMOVE_LAYER,
  ADD_CARD,
  REMOVE_CARD,
  LayerActionTypes,
  LayersState,
  SET_CARD,
  SET_SELECTED_CARD,
  SET_STATE,
  SET_ORDER,
  ADD_SELECTED_CARD,
  SET_CARDS,
  MIRROR_CARDS,
  SELECT_IN_RECT,
  SET_EXTRA_CARDS,
  LAYOUT_COPY,
  LAYOUT_PASTE,
  SET_EXTRA,
  Layer,
} from './types';
import _ from 'lodash';
import { GetSelectionGridRect } from '../../CardTool/MainView/state';
import { Card } from '../cards/types';
import { getID } from '../helper';
import { getSelectedLayer } from '../selectors';

const initialState: LayersState = { layers: [], cards: {}, selected: [], extraCards: 0 };

const checkCardCollision = (state: LayersState, cards: string[], x: number, y: number, ignoreId: string = '') => {
  return !cards.every((id) => id === ignoreId || (state.cards[id].x - x) ** 2 + (state.cards[id].y - y) ** 2 >= 1);
};

export function layerReducer(state = initialState, action: LayerActionTypes): LayersState {
  switch (action.type) {
    case ADD_LAYER:
      return {
        ...state,
        layers: [...state.layers, { id: action.id, editChecked: false, showChecked: true, cards: [] }],
      };
    case SET_LAYER: {
      let newState = { ...state, layers: [...state.layers] };
      let layers = newState.layers;
      if (action.conf.editChecked) _.forEach(layers, (v, i) => (layers[i] = { ...layers[i], editChecked: false }));
      const index = _.findIndex(layers, (o) => o.id === action.conf.id);
      if (index >= 0) layers[index] = { ...action.conf };
      return newState;
    }
    case REMOVE_LAYER:
      const newState = { ...state, layers: [...state.layers] };
      const layerId = state.layers.findIndex((o) => o.id === action.id);
      state.layers[layerId].cards.forEach((cardId) => {
        delete state.cards[cardId];
      });
      newState.layers.splice(layerId, 1);
      return newState;
    case ADD_CARD: {
      const checkedIndex = _.findIndex(state.layers, (layer) => layer.editChecked);
      if (checkedIndex < 0) return state;
      const checkedLayerCardIds = state.layers[checkedIndex].cards;
      if (checkCardCollision(state, checkedLayerCardIds, action.card.x, action.card.y)) return state;
      const newState = {
        ...state,
        layers: [...state.layers],
        cards: { ...state.cards, [action.card.id]: action.card },
      };
      newState.layers[checkedIndex].cards.push(action.card.id);
      return newState;
    }
    case REMOVE_CARD: {
      const checkedIndex = _.findIndex(state.layers, (layer) => layer.editChecked);
      if (checkedIndex < 0) return state;
      const newState = {
        ...state,
        layers: [...state.layers],
        cards: _.pickBy(state.cards, (o) => o.id !== action.id),
      };
      newState.layers[checkedIndex].cards = newState.layers[checkedIndex].cards.filter((o) => o !== action.id);
      return newState;
    }
    case SET_CARD: {
      const editLayer = getSelectedLayer(state);
      if (!editLayer) return state;
      if (checkCardCollision(state, editLayer.cards, action.card.x, action.card.y, action.card.id)) return state;
      return { ...state, layers: [...state.layers], cards: { ...state.cards, [action.id]: action.card } };
    }
    case SET_CARDS: {
      return { ...state, layers: [...state.layers], cards: { ...state.cards, ...action.cards } };
    }
    case SET_SELECTED_CARD: {
      if (!getSelectedLayer(state)) return state;
      return { ...state, selected: action.id ? [action.id] : [] };
    }
    case ADD_SELECTED_CARD: {
      if (!getSelectedLayer(state)) return state;
      return { ...state, selected: [...state.selected, action.id] };
    }
    case SET_STATE: {
      return {
        layers: action.state.layers || [],
        cards: action.state.cards || {},
        selected: [],
        copied: state.copied,
        extra: action.state.extra,
        extraCards: action.state.extraCards || 0,
      };
    }
    case SET_ORDER: {
      const newState = { ...state, layers: [...state.layers], cards: { ...state.cards } };
      newState.layers = action.order.map((index) => newState.layers[index]);
      return newState;
    }
    case SET_EXTRA_CARDS: {
      return { ...state, extraCards: action.extraCards };
    }
    case SET_EXTRA: {
      return { ...state, extra: action.value };
    }
    case MIRROR_CARDS: {
      let newState = { ...state, layers: [...state.layers], cards: { ...state.cards } };
      newState = mirrorCards(newState, action.mirrorX, action.mirrorY, action.mirrorAngle);
      return newState;
    }
    case SELECT_IN_RECT: {
      const layer = getSelectedLayer(state);
      if (!layer) return state;
      let { x, y, w, h } = GetSelectionGridRect(action.x, action.y, action.w, action.h);
      let newState = { ...state, layers: [...state.layers], cards: { ...state.cards } };
      newState.selected = [];
      layer.cards.forEach((cardId) => {
        const card = newState.cards[cardId];
        if (card.x >= x && card.x <= w && card.y >= y && card.y <= h) newState.selected.push(cardId);
      });
      return newState;
    }
    case LAYOUT_COPY: {
      let copied = state.layers.map((layer) => layer.cards.map((id) => ({ ...state.cards[id] })));
      return { ...state, copied: copied };
    }
    case LAYOUT_PASTE: {
      if (!state.copied) return state;
      let newState = { ...state };
      for (let i = 0; i < (state.copied?.length || 0); i++) {
        const newLayer = { id: getID(), editChecked: false, showChecked: true, cards: [] } as Layer;
        state.copied[i].forEach((card: Card) => {
          const newId = getID();
          newState.cards[newId] = { ...card, id: newId };
          newLayer.cards.push(newId);
        });
        newState.layers.push(newLayer);
      }
      return newState;
    }
    default:
      return state;
  }
}

function mirrorCards(state: LayersState, mirrorX: boolean, mirrorY: boolean, mirrorAngle: boolean) {
  const layer = getSelectedLayer(state);
  if (!layer) return state;
  const toTransfer: Card[] = [];
  _.keys(state.cards).forEach((cardId) => {
    const card = state.cards[cardId];
    if (state.selected.indexOf(card.id) >= 0) {
      toTransfer.push(card);
    }
  });
  toTransfer.forEach((card) => {
    const newX = mirrorX ? -card.x : card.x;
    const newY = mirrorY ? -card.y : card.y;
    const newRotation = mirrorAngle ? -card.rotation : card.rotation;
    if (!checkCardCollision(state, layer.cards, newX, newY)) {
      const cardCopy = { ...card, id: getID(), x: newX, y: newY, rotation: newRotation };
      state.cards[cardCopy.id] = cardCopy;
      layer.cards.push(cardCopy.id);
    }
  });
  return state;
}
