import { Card } from '../cards/types';

export const ADD_LAYER = 'ADD_LAYER';
export const SET_LAYER = 'SET_LAYER';
export const REMOVE_LAYER = 'REMOVE_LAYER';
export const ADD_CARD = 'ADD_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const SET_CARD = 'SET_CARD';
export const SET_CARDS = 'SET_CARDS';
export const SET_SELECTED_CARD = 'SET_SELECTED_CARD';
export const ADD_SELECTED_CARD = 'ADD_SELECTED_CARD';
export const SET_STATE = 'SET_STATE';
export const SET_ORDER = 'SET_ORDER';
export const SET_EXTRA_CARDS = 'SET_EXTRA_CARDS';
export const MIRROR_CARDS = 'MIRROR_CARDS';
export const SELECT_IN_RECT = 'SELECT_IN_RECT';
export const LAYOUT_COPY = 'LAYOUT_COPY';
export const LAYOUT_PASTE = 'LAYOUT_PASTE';
export const SET_EXTRA = 'SET_EXTRA';

export interface Layer {
  id: string;
  editChecked: boolean;
  showChecked: boolean;
  cards: Array<string>;
}

export interface LayersState {
  copied?: Array<Array<Card>>;
  selected: Array<string>;
  cards: Record<string, Card>;
  layers: Array<Layer>;
  extraCards: number;
  extra?: string;
}

export interface AddLayerAction {
  type: typeof ADD_LAYER;
  id: string;
}

export interface SetLayerAction {
  type: typeof SET_LAYER;
  conf: Layer;
}

export interface RemoveLayerAction {
  type: typeof REMOVE_LAYER;
  id: string;
}

export interface AddCardAction {
  type: typeof ADD_CARD;
  card: Card;
}

export interface SetCardAction {
  type: typeof SET_CARD;
  card: Card;
  id: string;
}

export interface SetCardsAction {
  type: typeof SET_CARDS;
  cards: Record<string, Card>;
}

export interface SetSelectedCardAction {
  type: typeof SET_SELECTED_CARD;
  id: string | null;
}

export interface AddSelectedCardAction {
  type: typeof ADD_SELECTED_CARD;
  id: string;
}

export interface RemoveCardAction {
  type: typeof REMOVE_CARD;
  id: string;
}

export interface SetOrederAction {
  type: typeof SET_ORDER;
  order: number[];
}

export interface SetLayersState {
  type: typeof SET_STATE;
  state: LayersState;
}

export interface SetExtraCardsAction {
  type: typeof SET_EXTRA_CARDS;
  extraCards: number;
}

export interface MirrorCardsAction {
  type: typeof MIRROR_CARDS;
  mirrorX: boolean;
  mirrorY: boolean;
  mirrorAngle: boolean;
}

export interface CopyLayoutAction {
  type: typeof LAYOUT_COPY;
}

export interface PasteLayoutAction {
  type: typeof LAYOUT_PASTE;
}

export interface SetExtraAction {
  type: typeof SET_EXTRA;
  value?: string;
}

export interface SelectInRectAction {
  type: typeof SELECT_IN_RECT;
  x: number;
  y: number;
  w: number;
  h: number;
}

export type LayerActionTypes =
  | AddLayerAction
  | SetLayerAction
  | RemoveLayerAction
  | AddCardAction
  | RemoveCardAction
  | SetCardAction
  | SetCardsAction
  | SetSelectedCardAction
  | SetLayersState
  | SetOrederAction
  | AddSelectedCardAction
  | SetExtraCardsAction
  | MirrorCardsAction
  | CopyLayoutAction
  | PasteLayoutAction
  | SetExtraAction
  | SelectInRectAction;
