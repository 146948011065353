import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Main from './Main';
import AuthPage from './Auth/AuthPage';
import { Switch, Route } from 'react-router-dom';
import './index.css';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Switch>
          <Route path="/login">
            <AuthPage />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
