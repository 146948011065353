import React from 'react';
import { ControlsWrap, ControlButton } from './styles';
import { getJsonData } from '../../store/selectors';
import _ from 'lodash';
import { Props } from './';

export const Controls: React.FC<Props> = (props: Props) => {
  const handleSave = () => {
    if (props.chosenLevel !== null) props.thunkSaveLevel(props.chosenLevel, getJsonData(props.state.layers));
  };
  return (
    <ControlsWrap>
      <ControlButton onClick={handleSave}>SAVE</ControlButton>
    </ControlsWrap>
  );
};

export default Controls;
