import React, { useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import * as State from '../MainView/state';
import { Card } from '../../store/cards/types';
import CardImage from '../CardImage';
import { getID } from '../../store/helper';
import { Props } from './';

const useStyles = makeStyles<Theme, { sizes: [number, number] }>((theme) => ({
  root: (props) => ({
    position: 'relative',
    width: `${props.sizes[0]}px`,
    height: `${props.sizes[1]}px`,
    zIndex: 10,
  }),
  animated: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

let lastRect = new DOMRect();

export const CardTool: React.FC<Props> = ({ tools, addCard }: Props) => {
  const sizes = State.getCardSizes();
  const classes = useStyles({ sizes });
  const divRef = useRef<HTMLDivElement>(null);
  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));
  const bind = useDrag(({ xy: [px, py], initial, last, first }) => {
    if (first) {
      lastRect = divRef.current?.getBoundingClientRect() || new DOMRect();
    }
    const dx = initial[0] - lastRect.left + 1.3,
      dy = initial[1] - lastRect.top;
    let pos = State.GetGridPosition(px - dx, py - dy);
    let { x, y } = State.GetRealPosition(pos.x, pos.y, initial);
    set({ x: x + dx, y: y + dy, immediate: true });
    if (last) {
      const newCard: Card = {
        id: getID(),
        x: pos.x,
        y: pos.y,
        p1: tools.cardId,
        p2: tools.cardKind,
        rotation: tools.rotation,
      };
      addCard(newCard);
      set({ x: 0, y: 0, immediate: true });
    }
  });
  return (
    <div ref={divRef} className={classes.root}>
      <animated.div
        {...bind()}
        className={classes.animated}
        style={{ x, y, transform: `rotate(${tools.rotation}deg)` }}
      >
        <CardImage kind={tools.cardKind} sizes={sizes} />
      </animated.div>
    </div>
  );
};

export default CardTool;
