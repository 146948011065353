import React from 'react';
import { LevelConfigItemInterface } from './LevelTasksItem';
import { LevelConfigFiguresItemInterface } from './LevelConfigFigures';

export interface ConfigContextInterface {
  FiguresGetter: () => LevelConfigFiguresItemInterface[];
  ScenarioGetter: () => string[];
  TasksGetter: () => LevelConfigItemInterface[];
  LevelGetter: () => [];
  StarsGetter: () => [number, number];

  FiguresSetter: (data: LevelConfigFiguresItemInterface[]) => void;
  ScenarioSetter: (data: string[]) => void;
  TasksSetter: (data: LevelConfigItemInterface[]) => void;
  LevelSetter: (data: any) => void;
  StarsSetter: (data: [number, number]) => void;

  LevelNumberSetter: (level: number) => void;
  OnLevelAdd: () => void;
  LevelsLength: number;
  CurrentLevel: number;
}

export const ConfigContext = React.createContext<ConfigContextInterface>({
  FiguresGetter: () => [],
  ScenarioGetter: () => [],
  TasksGetter: () => [],
  LevelGetter: () => [],
  StarsGetter: () => [1, 2],
  FiguresSetter: (data: LevelConfigFiguresItemInterface[]) => {},
  ScenarioSetter: (data: string[]) => {},
  TasksSetter: (data: LevelConfigItemInterface[]) => {},
  LevelSetter: (data: any) => {},
  StarsSetter: (data: [number, number]) => {},

  LevelNumberSetter: (level: number) => {},
  OnLevelAdd: () => {},
  LevelsLength: 0,
  CurrentLevel: 0,
});
