import gem1 from './LevelTool/i/gem1.png';
import gem2 from './LevelTool/i/gem2.png';
import gem3 from './LevelTool/i/gem3.png';
import gem4 from './LevelTool/i/gem4.png';
import gem5 from './LevelTool/i/gem5.png';
import gem6 from './LevelTool/i/gem6.png';
import glass from './LevelTool/i/cell-glass.png';
import boulder from './LevelTool/i/cell-stone.png';

export const Gems = [gem1, gem2, gem3, gem4, gem5, gem6];
export const Glass = glass;
export const Boulder = boulder;
