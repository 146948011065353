import React, { useState, useContext, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import _ from 'lodash';
import { LevelConfigItemInterface, ConfigItemValueType } from './LevelTasksItem';
import { LevelConfigFiguresItemInterface } from './LevelConfigFigures';
import { ConfigContext, ConfigContextInterface } from './Config';
let copiedLevel = { data: {}, num: -1 };

function tasks2Config(task: LevelConfigItemInterface): [string | number, ConfigItemValueType] {
  switch (task.configItemType) {
    case 'boulder':
      return ['B', task.value];
    case 'glass':
      return ['G', task.value];
    case 'gem':
      return [task.gemStyle || 0, task.value];
    default:
      return ['B', task.value];
  }
}

function config2tasks(data: [string | number, ConfigItemValueType], id: number): LevelConfigItemInterface {
  switch (data[0]) {
    case 'B':
      return { configItemType: 'boulder', value: data[1], id: id };
    case 'G':
      return { configItemType: 'glass', value: data[1], id: id };
    default:
      return { configItemType: 'gem', gemStyle: +data[0], value: data[1], id: id };
  }
}

function config2figures(data: [number, number, number], id: number): LevelConfigFiguresItemInterface {
  return { from: data[0], to: data[1], moveNumber: data[2], idKey: id };
}

function getConfig(configContext: ConfigContextInterface) {
  let config: { levels: any; tasks: any; figures: any; scenario: any; stars: [number, number] } = {
    levels: {},
    tasks: {},
    figures: {},
    scenario: {},
    stars: [1, 2],
  };
  config.levels = configContext.LevelGetter();
  config.tasks = configContext.TasksGetter().map((value: LevelConfigItemInterface) => tasks2Config(value));
  config.figures = configContext
    .FiguresGetter()
    .map((value: LevelConfigFiguresItemInterface) => [value.from, value.to, value.moveNumber]);
  config.scenario = configContext.ScenarioGetter();
  config.stars = configContext.StarsGetter();
  return config;
}

function setConfig(configContext: ConfigContextInterface, res: any) {
  if (res.figures)
    configContext.FiguresSetter(
      res.figures.map((v: [number, number, number], index: number) => config2figures(v, index)),
    );
  else configContext.FiguresSetter([]);
  configContext.LevelSetter(res.levels || []);
  configContext.ScenarioSetter(res.scenario || []);
  configContext.StarsSetter(res.stars || [1, 2]);
  if (res.tasks) configContext.TasksSetter(res.tasks.map((v: any, index: number) => config2tasks(v, index)));
  else configContext.TasksSetter([]);
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  button: {
    fontSize: '15px',
    margin: 'auto',
    padding: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: '50px',
  },
  image: {
    width: '20px',
    marginRight: '10px',
  },
}));

const fetchApi = async function (
  postfix: string,
  method: 'POST' | 'GET' | 'DELETE' = 'GET',
  data: any = null,
): Promise<any> {
  const response = await fetch(`https://api.bigbutton.co/mds/${postfix}`, {
    method: method,
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    body: data && JSON.stringify(data),
  });
  if (response.redirected) {
    window.location.replace(response.url);
    return null;
  }
  return response.json();
};

export default function SaveButton() {
  const configContext = useContext(ConfigContext);
  const [levelNumber, setLevelNumber] = useState(-1);
  const classes = useStyles();
  useEffect(() => {
    if (levelNumber > -1) {
      console.log(`fetchLevel number ${levelNumber}`);
      fetchApi(`level/${levelNumber}`).then((res) => {
        setConfig(configContext, res);
      });
    }
  }, [configContext, levelNumber]);
  const saveData = async () => {
    let config = getConfig(configContext);
    fetchApi(`level/${levelNumber}`, 'POST', { level: config });
  };
  const addLevel = async () => {
    fetchApi(`level/empty/${configContext.LevelsLength}`, 'POST').then(() => {
      updateNumLevels();
    });
  };
  const insertLevel = async () => {
    fetchApi(`level/empty/${levelNumber}`, 'POST').then(() => {
      setLevelNumber(levelNumber + 1);
      updateNumLevels();
    });
  };
  const deleteLevel = async () => {
    fetchApi(`level/${levelNumber}`, 'DELETE').then(() => {
      updateNumLevels();
    });
  };
  const pasteLevel = useCallback(() => {
    setConfig(configContext, copiedLevel.data);
  }, [configContext]);
  const copyLevel = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      copiedLevel.data = getConfig(configContext);
      copiedLevel.num = levelNumber;
      event.currentTarget.value = `#${levelNumber} is coppied`;
    },
    [configContext, levelNumber],
  );
  const updateNumLevels = useCallback(() => {
    fetchApi(`levels/length`).then((res) => {
      if (res) configContext.LevelsLength = res.length;
      else configContext.LevelsLength = 0;
      configContext.OnLevelAdd();
    });
  }, [configContext]);
  useEffect(() => {
    updateNumLevels();
  }, [updateNumLevels]);
  useEffect(() => {
    configContext.LevelNumberSetter = (levelNumber: number) => {
      setLevelNumber(levelNumber);
    };
  });
  return (
    <div className={classes.content}>
      {levelNumber >= 0 ? `Current Level: ${levelNumber + 1}` : 'Level is not chosen'}
      <Button onClick={saveData} color="primary" variant="contained" size="small">
        Save Level
      </Button>
      <Button onClick={addLevel} variant="contained" size="small">
        Add Level
      </Button>
      <Button onClick={insertLevel} variant="contained" size="small">
        Insert Level
      </Button>
      <Button onClick={deleteLevel} variant="contained" color="secondary" size="small">
        Delete Level
      </Button>
      <Button onClick={copyLevel} variant="contained" size="small">
        Copy Level
      </Button>
      <Button onClick={pasteLevel} variant="contained" size="small">
        Paste Level
      </Button>
    </div>
  );
}
