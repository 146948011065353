import React from 'react';
import { useEffect, useRef, useContext } from 'react';
import GemMenu from './GemMenu';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { InitTool, TetrisInstance } from './LevelTool/main';
import { Gems, Glass, Boulder } from './Utils';
import { ConfigContext } from './Config';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    '& img': {
      width: '40px',
      height: '40px',
    },
    '& svg': {
      width: '40px',
      height: '40px',
      padding: '0px',
    },
    '& button': {
      padding: '5px',
    },
  },

  marginRight: {
    margin: '0 25px 0 0',
  },
}));

export default function LevelTool() {
  const config = useContext(ConfigContext);
  const pixiDivRef = useRef<HTMLDivElement>(null);
  const tetrisAppRef = React.useRef<TetrisInstance | null>(null);
  const classes = useStyles();
  const [tools, setTools] = React.useState(() => ['object']);
  const [clearToolSelected, setClearTool] = React.useState<boolean>(false);
  const [gemStyle, setGemStyle] = React.useState<number>(1);
  const handleTools = function (event: React.MouseEvent<HTMLElement>, newTools: string[]) {
    setTools(newTools);
    if (tetrisAppRef.current) tetrisAppRef.current.SetToolsConf(newTools);
  };
  useEffect(() => {
    tetrisAppRef.current = InitTool(pixiDivRef.current);
    return () => {
      if (tetrisAppRef.current) tetrisAppRef.current.Destroy();
    };
  }, []);
  useEffect(() => {
    config.LevelGetter = () => {
      if (tetrisAppRef.current) return tetrisAppRef.current.GetLevelConfig();
    };
    config.LevelSetter = (data) => {
      if (tetrisAppRef.current) tetrisAppRef.current.SetLevelConfig(data);
    };
    return () => {
      config.LevelGetter = () => [];
    };
  }, [config]);
  return (
    <Container>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.toggleButton}>
        <div className={classes.marginRight}>
          <GemMenu
            choiseCallback={(id: number) => {
              setGemStyle(id);
              if (tetrisAppRef.current) tetrisAppRef.current.SetGemStyle(id - 1);
            }}
          ></GemMenu>
        </div>
        <ToggleButtonGroup
          value={tools}
          size="small"
          onChange={handleTools}
          className={classes.marginRight}
          aria-label="text formatting"
        >
          <ToggleButton value="object" aria-label="bold">
            <img src={Gems[gemStyle - 1]} alt={'GEM'} />
          </ToggleButton>
          <ToggleButton value="boulder" aria-label="italic">
            <img src={Boulder} alt={'B'} />
          </ToggleButton>
          <ToggleButton value="glass" aria-label="italic">
            <img src={Glass} alt={'G'} />
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton
          value="check"
          selected={clearToolSelected}
          onChange={() => {
            if (tetrisAppRef.current) tetrisAppRef.current.SetClearToolSelected(!clearToolSelected);
            setClearTool(!clearToolSelected);
          }}
        >
          <RemoveCircleOutlineIcon />
        </ToggleButton>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div ref={pixiDivRef} style={{ width: '400px', height: '400px', margin: 'auto' }}></div>
      </div>
    </Container>
  );
}
