import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import _ from 'lodash';
import { ConfigContext } from './Config';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
  },
  button: {
    fontSize: '15px',
    margin: 'auto',
    padding: theme.spacing(1),
  },
  input: {
    width: '30px',
  },
}));

export default function LevelConfigColors() {
  const config = useContext(ConfigContext);
  const [stars, setStars] = useState<[number, number]>([1, 2]);
  const classes = useStyles();
  useEffect(() => {
    config.StarsGetter = () => {
      return stars;
    };
    config.StarsSetter = (data: [number, number]) => {
      setStars(data);
    };
  });
  return (
    <div className={classes.buttons}>
      {[...Array(2)].map((value, index) => (
        <div key={index} className={classes.button}>
          <TextField
            className={classes.input}
            value={stars[index] || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let newStars: [number, number] = [stars[0], stars[1]];
              newStars[index] = parseInt(event.target.value);
              setStars(newStars);
            }}
            label={'star' + (index + 2)}
            size="small"
          />
        </div>
      ))}
    </div>
  );
}
