import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import DehazeOutlinedIcon from '@material-ui/icons/DehazeOutlined';
import Button from '@material-ui/core/Button';
import { Gems, Glass, Boulder } from './Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignContent: 'flex-start',
    height: '30px',
    margin: 0,
    padding: '2px',
    backgroundColor: theme.palette.grey[300],
    '& svg': {
      padding: '2px',
    },
    '& button': {
      width: '10px',
      padding: '2px',
    },
  },
  gem: {
    height: '100%',
  },
  delete: {
    marginLeft: 'auto',
  },
}));

const ConfigItemValuesConst = [1, 4, 9, 16, 25, 36] as const;
type ConfigItemValuesConstType = typeof ConfigItemValuesConst;
type ConfigItemValue = ConfigItemValuesConstType[number];

export type ConfigItemValueType = ConfigItemValue;

export interface LevelConfigItemInterface {
  configItemType: 'boulder' | 'glass' | 'gem';
  gemStyle?: number;
  value: ConfigItemValue;
  id: number;
}

export default function LevelConfigItem(Props: {
  config: LevelConfigItemInterface;
  onMovableCallback: () => void;
  onDeleteCallback: () => void;
  setItemCallback: (id: number, value: ConfigItemValue) => void;
}) {
  const [value, setValue] = useState<ConfigItemValue>(Props.config.value);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = function () {
    setAnchorEl(null);
  };
  const handleChoise = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    const newValue = ConfigItemValuesConst[parseInt(event.currentTarget.id)];
    Props.setItemCallback(Props.config.id, newValue);
    setValue(newValue);
  };
  useEffect(() => {
    setValue(Props.config.value);
  }, [Props.config.value]);

  return (
    <Card className={classes.root}>
      <Button onPointerDown={Props.onMovableCallback}>
        <DehazeOutlinedIcon />
      </Button>
      {Props.config.configItemType === 'gem' && (
        <img src={Gems[Props.config.gemStyle ? Props.config.gemStyle - 1 : 0]} className={classes.gem} alt={'GEM'} />
      )}
      {Props.config.configItemType === 'boulder' && <img src={Boulder} className={classes.gem} alt={'boulder'} />}
      {Props.config.configItemType === 'glass' && <img src={Glass} className={classes.gem} alt={'glass'} />}
      <Button aria-label="value" aria-controls="value-menu" aria-haspopup="true" onClick={handleClick}>
        <ArrowDropDownOutlinedIcon />
        {value}
      </Button>
      <Menu
        // className={classes.toggleButton}
        id="value-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {ConfigItemValuesConst.map((value, index) => (
          <MenuItem key={index} id={index.toString()} onClick={handleChoise}>
            {value}
          </MenuItem>
        ))}
      </Menu>
      <Button className={classes.delete} onClick={Props.onDeleteCallback}>
        <DeleteOutlineOutlinedIcon />
      </Button>
    </Card>
  );
}
