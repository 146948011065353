import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ToolState } from '../../store';
import { setChosenLevel, setNumberLevels } from '../../store/tools/actions';
import {
  thunkAddLevel,
  thunkGetLevel,
  thunkDeleteLevel,
  thunkGetNumberLevels,
  copyLayout,
  pasteLayout,
} from '../../store/layers/actions';
import LevelsBar from './LevelsBar';
import { AllSolitareModels, CatsVsZombies, SolitaireModel } from '../../api/types';

const mapStateToProps = (state: ToolState) => ({
  chosenLevel: state.tools.chosenLevel,
  numberLevels: state.tools.numberLevels,
});

const getMapDispatchToProps = (model: AllSolitareModels) => {
  return {
    copyLayout,
    pasteLayout,
    setChosenLevel: (level: number) => setChosenLevel(level),
    setNumberLevels: (size: number) => setNumberLevels(size),
    thunkAddLevel: (level: number) => thunkAddLevel(model, level),
    thunkGetLevel: (level: number) => thunkGetLevel(model, level),
    thunkDeleteLevel: (level: number) => thunkDeleteLevel(model, level),
    thunkGetNumberLevels: () => thunkGetNumberLevels(model),
  };
};

const solitaireConnector = connect(mapStateToProps, getMapDispatchToProps(SolitaireModel));
const catsVsZombiesConnector = connect(mapStateToProps, getMapDispatchToProps(CatsVsZombies));

export type Props = ConnectedProps<typeof solitaireConnector | typeof catsVsZombiesConnector>;

const LevelsBarWrapper = ({ model }: { model: AllSolitareModels }) => {
  let Connector;
  switch (model) {
    case SolitaireModel:
      Connector = solitaireConnector(LevelsBar);
      break;
    case CatsVsZombies:
      Connector = catsVsZombiesConnector(LevelsBar);
      break;
    default:
      Connector = solitaireConnector(LevelsBar);
      break;
  }
  return <Connector />;
};

export default LevelsBarWrapper;
