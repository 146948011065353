import { connect, ConnectedProps } from 'react-redux';
import { ToolState } from '../../store';
import { setExtra, setExtraCards } from '../../store/layers/actions';
import GameConfigs from './GameConfigs';

const mapStateToProps = (state: ToolState) => ({
  extra: state.layers.extra,
  extraCards: state.layers.extraCards,
});

const mapDispatchToProps = {
  setExtraCards: (extraCards: number) => setExtraCards(extraCards),
  setExtra: (extra: string) => setExtra(extra),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(GameConfigs);
