import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Gems } from './Utils';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    '& img': {
      width: '35px',
      height: '35px',
    },
    '& svg': {
      width: '50px',
      height: '50px',
    },
  },
}));

export default function GemMenu(Props: { choiseCallback: (id: number) => void }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = function () {
    setAnchorEl(null);
  };
  const handleChoise = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    let gemN = Math.min(Math.max(1, parseInt(event.currentTarget.id) + 1), 6);
    Props.choiseCallback(gemN);
  };
  return (
    <div>
      <Button aria-controls="gem-menu" aria-haspopup="true" onClick={handleClick}>
        <ArrowDropDownOutlinedIcon />
      </Button>
      <Menu
        className={classes.toggleButton}
        id="gem-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Gems.map((value, index) => (
          <MenuItem key={index} id={index.toString()} onClick={handleChoise}>
            <img src={value} alt={'GEM'} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
