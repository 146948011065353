import { connect, ConnectedProps } from 'react-redux';
import { Card } from '../../store/cards/types';
import { ToolState } from '../../store';
import { addCard, setCard, setCards, setSelectedCard, addSelectedCard } from '../../store/layers/actions';
import { getVisibleCards } from '../../store/selectors';
import View from './view';

const mapStateToProps = (state: ToolState) => ({
  cards: getVisibleCards(state),
  isSelected: (id: string) => state.layers.selected.indexOf(id) >= 0,
});

const mapDispatchToProps = {
  addCard: (card: Card) => addCard(card),
  setCards: (cards: Record<string, Card>) => setCards(cards),
  setCard: (id: string, card: Card) => setCard(id, card),
  setSelectedCard: (id: string | null) => setSelectedCard(id),
  addSelectedCard: (id: string) => addSelectedCard(id),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(View);
