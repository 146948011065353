import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const ControlsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  & > * {
    margin-top: 5px;
  }
  & > *:first-child {
    margin-top: 0px;
  }
`;

export const ControlButton = styled(Button)`
  width: 100%;
`;
