import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import DehazeOutlinedIcon from '@material-ui/icons/DehazeOutlined';
import Button from '@material-ui/core/Button';

import { Layer as LayerType } from '../../store/layers/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: '30px',
    margin: 0,
    padding: '2px',
    backgroundColor: theme.palette.grey[300],
    '& svg': {
      padding: '2px',
    },
    '& button': {
      width: '10px',
      padding: '2px',
    },
  },
  text: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  switch: {},
  delete: {
    marginLeft: 'auto',
  },
}));

export default function Layer(Props: {
  config: LayerType;
  onMovableCallback: () => void;
  onDeleteCallback: () => void;
  setItemCallback: (args: LayerType) => void;
}) {
  const classes = useStyles();

  const setItemEdit = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    Props.setItemCallback({
      id: Props.config.id,
      showChecked: Props.config.showChecked,
      editChecked: checked,
      cards: [...Props.config.cards],
    });
  };

  const setItemShow = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    Props.setItemCallback({
      id: Props.config.id,
      editChecked: Props.config.editChecked,
      showChecked: checked,
      cards: [...Props.config.cards],
    });
  };

  return (
    <Card className={classes.root}>
      <Button onPointerDown={Props.onMovableCallback}>
        <DehazeOutlinedIcon />
      </Button>
      <Switch
        className={classes.switch}
        checked={Props.config.editChecked}
        size="small"
        onChange={setItemEdit}
        inputProps={{ 'aria-label': 'default checkbox' }}
      />
      <Checkbox
        checked={Props.config.showChecked}
        onChange={setItemShow}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <span className={classes.text}>= {Props.config.cards.length}</span>
      <Button className={classes.delete} onClick={Props.onDeleteCallback}>
        <DeleteOutlineOutlinedIcon />
      </Button>
    </Card>
  );
}
