import React, { useRef, useState, useContext, useEffect } from 'react';
import * as _ from 'lodash';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { clamp } from 'lodash';
import move from './LevelTool/lodash_move';
import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';
import LevelConfigItem, { LevelConfigItemInterface, ConfigItemValueType } from './LevelTasksItem';
import { Glass, Boulder } from './Utils';
import { ConfigContext } from './Config';
import GemMenu from './GemMenu';

const useStyles = makeStyles((theme) => ({
  tools: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: '15px',
    '& button': {
      padding: '0px',
      margin: '2px',
    },
  },
  btn: {
    height: '40px',
  },
}));

const dy = 40;

const fn = (order: number[], imm?: boolean, down?: boolean, originalIndex?: number, curIndex?: number, y?: number) => (
  index: number,
) =>
  down && index === originalIndex && curIndex !== undefined && y
    ? {
        y: curIndex * dy + y,
        scale: 1.1,
        zIndex: 1,
        shadow: 15,
        immediate: (key: string) => key === 'y' || key === 'zIndex',
      }
    : { y: order.indexOf(index) * dy, scale: 1, zIndex: 0, shadow: 1, immediate: imm };

export default function LevelConfig() {
  const config = useContext(ConfigContext);
  const classes = useStyles();
  const [items, setItems] = useState<LevelConfigItemInterface[]>([]);
  const lastElementId = useRef(-1);
  const order = useRef(items.map((_, index) => index));
  const activeElementIndex = useRef(-1);
  const [springs, setSprings] = useSprings(items.length, fn(order.current, true));
  for (let i = 0; i < order.current.length; i++) {
    springs[order.current[i]].y.set(dy * i + 0.01);
  }
  setSprings(fn(order.current, true));
  useEffect(() => {
    config.TasksGetter = () => {
      let orderedItems: LevelConfigItemInterface[] = [];
      for (let i = 0; i < order.current.length; i++) {
        orderedItems[i] = items[order.current[i]];
      }
      return orderedItems;
    };
    config.TasksSetter = (data: LevelConfigItemInterface[]) => {
      lastElementId.current = data.length;
      order.current = data.map((_, index) => index);
      setItems(data);
    };
  });
  // _.forEach(springs, (s) => { s.y. });
  // for (let i = 0; i < springs.length; i++) springs[i].y.key = i.toString() + items[i].id;
  const addConfigItem = function (item: LevelConfigItemInterface) {
    const newItems = [...items, item];
    order.current.push(newItems.length - 1);
    setItems(newItems);
  };
  const setItem = function (id: number, value: ConfigItemValueType) {
    let index = _.findIndex(items, { id: id });
    let item = items[index];
    let newItems = [...items];
    newItems.splice(index, 1, {
      id: id,
      value: value,
      configItemType: item.configItemType,
      gemStyle: item.gemStyle,
    });
    setItems(newItems);
  };
  const deleteConfigItem = function (id: number) {
    _.remove(order.current, (n) => n === id);
    setItems(_.without(items, items[id]));
    for (let i = 0; i < order.current.length; i++) {
      order.current[i] = order.current[i] >= id ? --order.current[i] : order.current[i];
    }
  };
  const bind = useDrag(({ args: [originalIndex], down, movement: [, y] }) => {
    if (originalIndex !== activeElementIndex.current) return;
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(Math.round((curIndex * dy + y) / dy), 0, items.length - 1);
    const newOrder = move(order.current, curIndex, curRow);
    setSprings(fn(newOrder, false, down, originalIndex, curIndex, y));
    if (!down) {
      order.current = newOrder;
      activeElementIndex.current = -1;
    }
  });
  return (
    <Box>
      <div className={classes.tools}>
        <GemMenu
          choiseCallback={(id: number) => {
            addConfigItem({ configItemType: 'gem', gemStyle: id, id: ++lastElementId.current, value: 1 });
          }}
        ></GemMenu>
        <Button
          size="medium"
          variant="contained"
          onClick={() => addConfigItem({ configItemType: 'boulder', id: ++lastElementId.current, value: 1 })}
        >
          <img src={Boulder} className={classes.btn} alt={'Boulder'} />
        </Button>
        <Button
          size="medium"
          variant="contained"
          onClick={() => addConfigItem({ configItemType: 'glass', id: ++lastElementId.current, value: 1 })}
        >
          <img src={Glass} className={classes.btn} alt={'Glass'} />
        </Button>
      </div>
      <Box position="relative" style={{ height: '100%', width: '100%', userSelect: 'none' }}>
        {springs.map(({ zIndex, shadow, y, scale }, i) => (
          <animated.div
            {...bind(i)}
            key={items[i].id.toString()}
            style={{
              position: 'absolute',
              width: '95%',
              x: '2.5%',
              zIndex,
              boxShadow: shadow.to((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
              y,
              scale,
            }}
          >
            <LevelConfigItem
              config={items[i]}
              setItemCallback={setItem}
              onMovableCallback={() => {
                activeElementIndex.current = i;
              }}
              onDeleteCallback={() => {
                deleteConfigItem(i);
              }}
            />
          </animated.div>
        ))}
      </Box>
    </Box>
  );
}
