import React, { useRef, useEffect } from 'react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import * as _ from 'lodash';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { clamp } from 'lodash';
import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';
import move from '../../MdsTool/LevelTool/lodash_move';
import Layer from './Layer';
import { Props } from './';

const useStyles = makeStyles((theme) => ({
  tools: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingBottom: '15px',
    '& button': {
      padding: '0px',
      margin: '2px',
    },
  },
  btn: {
    height: '40px',
  },
  text: {
    textAlign: 'right',
    position: 'absolute',
    width: '100%',
    fontWeight: 500,
    lineHeight: '30px',
    pointerEvents: 'none',
  },
}));

const dy = 40;

const fn = (order: number[], imm?: boolean, down?: boolean, originalIndex?: number, curIndex?: number, y?: number) => (
  index: number,
) =>
  down && index === originalIndex && curIndex !== undefined && y
    ? {
        y: curIndex * dy + y,
        scale: 1.1,
        zIndex: 1,
        shadow: 15,
        immediate: (key: string) => key === 'y' || key === 'zIndex',
      }
    : { y: order.indexOf(index) * dy + 0.01, scale: 1, zIndex: 0, shadow: 1, immediate: imm };
let latestId = 0;
let editLayerId: string | null = null;

const FloorList = (props: Props) => {
  const classes = useStyles();
  const layers = props.state.layers;
  const order = useRef(layers.map((_, index) => index));
  const activeElementIndex = useRef(-1);
  const [springs, setSprings] = useSprings(layers.length, fn(order.current, true));
  useEffect(() => {
    for (let i = 0; i < layers.length; i++) {
      order.current[i] = order.current[i] === undefined ? i : order.current[i];
    }
    setSprings(fn(order.current, true));
  });
  const deleteConfigItem = function (index: number) {
    _.remove(order.current, (n) => n === index);
    for (let i = 0; i < order.current.length; i++) order.current[i] += order.current[i] >= index ? -1 : 0;
    props.removeLayer(layers[index].id);
  };
  const bind = useDrag(({ args: [originalIndex], down, movement: [, y] }) => {
    if (originalIndex !== activeElementIndex.current) return;
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(Math.round((curIndex * dy + y) / dy), 0, layers.length - 1);
    const newOrder = move(order.current, curIndex, curRow);
    setSprings(fn(newOrder, false, down, originalIndex, curIndex, y));
    if (!down) {
      props.setLayersOrder(newOrder);
      order.current = layers.map((_, index) => index);
      activeElementIndex.current = -1;
    }
  });
  const totalN = layers.reduce<number>((cum, layer) => cum + layer.cards.length, 0);
  return (
    <Box>
      <div className={classes.tools}>
        <Button aria-controls="gem-menu" aria-haspopup="true" onClick={props.addLayer}>
          <AddOutlinedIcon />
        </Button>
        <span className={classes.text}>= {totalN}</span>
      </div>
      <Box position="relative" style={{ height: '100%', width: '100%', userSelect: 'none' }}>
        {springs.map(({ zIndex, shadow, y, scale }, i) => (
          <animated.div
            {...bind(i)}
            key={layers[i].id.toString()}
            style={{
              position: 'absolute',
              width: '95%',
              x: '2.5%',
              zIndex,
              boxShadow: shadow.to((s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
              y,
              scale,
            }}
          >
            <Layer
              config={layers[i]}
              setItemCallback={props.setLayer}
              onMovableCallback={() => {
                activeElementIndex.current = i;
              }}
              onDeleteCallback={() => {
                deleteConfigItem(i);
              }}
            />
          </animated.div>
        ))}
      </Box>
    </Box>
  );
};

export default FloorList;
