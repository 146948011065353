import React from 'react';
import styled from 'styled-components';
import { getCardConfigs } from '../store/selectors';
import { CardViewProps, ImageCardProps } from './types';

function importAll(r: any) {
  return r.keys().reduce((out: any, key: string) => {
    out[key] = r(key);
    return out;
  }, {});
}
const images = importAll(require.context('./img', false, /\.(png)$/));
const cardImage = images['./bg.png'];
const cards = ['w', 'l', 'g', 'a1', 'a2', 'a3', 'a4', 'a5'];
const addons = ['c1', 'c2', 'c3', 'c4', 'k', 'ice1', 'ice2', 'd1', 'd2', 'd3', 'd4', 's'];

export const CardView = styled.div.attrs<CardViewProps>((props) => ({
  style: {
    border: props.chosen ? `2px purple solid` : `none`,
    width: `${props.sizes[0] - +props.chosen * 2}px`,
    height: `${props.sizes[1] - +props.chosen * 2}px`,
  },
}))<CardViewProps>`
  position: relative;
  border-radius: 8px;
  user-select: none;
  pointer-events: none;
  box-sizing: border-box;
`;

export const ImageView = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const BowView = styled.img`
  position: relative;
  height: 50%;
  margin-bottom: -50%;
`;

export const ImageCard: React.FC<ImageCardProps> = ({ kind, chosen, sizes }: ImageCardProps) => {
  const cardImgIndex = cards.indexOf(kind);
  const customCard = cardImgIndex >= 0;
  const addonImgIndex = addons.indexOf(kind);
  const hasAddon = addonImgIndex >= 0;
  const addonUrl = `./${addons[addonImgIndex]}.png`;
  const cardUrl = `./${cards[cardImgIndex]}.png`;
  const bowMath = kind.match(/^b([0-4]{1,3})$/);
  if (bowMath) {
    const conf = bowMath[1].split('');
    return (
      <CardView chosen={chosen || false} sizes={sizes}>
        <ImageView src={customCard ? images[cardUrl] : cardImage} alt={'Card'} />
        {conf.map((bowString) => {
          const bowNumber = parseInt(bowString);
          return !isNaN(bowNumber) && <BowView key={`bow${bowNumber}`} src={images[`./bow${bowNumber}.png`]}></BowView>;
        })}
      </CardView>
    );
  }
  return (
    <CardView chosen={chosen || false} sizes={sizes}>
      <ImageView src={customCard ? images[cardUrl] : cardImage} alt={'Card'} />
      {hasAddon && <ImageView src={images[addonUrl]} alt={'addon'} />}
    </CardView>
  );
};

export default ImageCard;
