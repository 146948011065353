import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import * as State from './state';
import { Card as CardType } from '../../store/cards/types';
import CardImage from '../CardImage';
import { useDrag } from 'react-use-gesture';
import { animated, useSpring } from 'react-spring';
import { getCardSizes } from './state';

const useStyles = makeStyles<Theme, { isSelected: boolean; rotation: number; width: number; height: number }>(
  (theme) => ({
    root: (props) => ({
      width: `${props.width}px`,
      height: `${props.height}px`,
      position: 'absolute',
      userSelect: 'none',
    }),
    animated: (props) => ({
      position: 'absolute',
      width: `${props.width}px`,
      height: `${props.height}px`,
      userSelect: 'none',
    }),
    card: (props) => ({
      userSelect: 'none',
      transform: `rotate(${props.rotation}deg)`,
    }),
  }),
);

const View: React.FC<
  CardType & {
    setCard: (id: string, card: CardType) => void;
    setSelected: (id: string | null) => void;
    addSelected: (id: string) => void;
    isSelected: (id: string) => boolean;
  }
> = (Props) => {
  const sizes = getCardSizes();
  const isCardSelected = Props.isSelected(Props.id);
  const classes = useStyles({
    isSelected: isCardSelected,
    rotation: Props.rotation,
    width: sizes[0],
    height: sizes[0],
  });
  const [{ x, y }, setSpring] = useSpring(() => ({ x: 0, y: 0 }));
  const dragBind = useDrag(({ movement: [dx, dy], last, shiftKey, altKey, cancel, canceled }) => {
    if (altKey || canceled) {
      setSpring({ x: 0, y: 0, immediate: true });
      cancel && cancel();
      return;
    }
    let pos = State.GetGridMovedPosition(Props.x, Props.y, dx, dy);
    let { x, y } = State.GetRelativePosition(pos.x, pos.y, false);
    if (last) {
      if (Math.abs(dx) + Math.abs(dy) > 5) {
        Props.setCard(Props.id, { ...Props, x: Props.x + pos.x, y: Props.y + pos.y });
      } else {
        if (shiftKey) {
          Props.addSelected(Props.id);
        } else {
          Props.setSelected(Props.id);
        }
      }
      setSpring({ x: 0, y: 0, immediate: true });
    } else {
      setSpring({ x, y, immediate: true });
    }
  });
  const getStyles = (): React.CSSProperties => {
    const pos = State.GetRelativePosition(Props.x, Props.y);
    return {
      left: `${pos.x}px`,
      top: `${pos.y}px`,
    };
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    State.setIgnoreSelected(true);
  };
  return (
    <div className={classes.root} style={getStyles()} onMouseDown={handleMouseDown}>
      <animated.div {...dragBind()} style={{ x, y }} className={classes.animated}>
        <div className={classes.card}>
          <CardImage chosen={isCardSelected} kind={Props.p2} sizes={sizes} />
        </div>
      </animated.div>
    </div>
  );
};

export default View;
