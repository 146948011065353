import { SET_ROTATION, SET_ID, SET_KIND, ToolsAction, SET_CHOSEN_LEVEL, SET_NUMBER_LEVELS, SET_DENSITY } from './types';

export function setRotation(rotation: number): ToolsAction {
  return {
    type: SET_ROTATION,
    rotation,
  };
}

export function setId(idConf: string): ToolsAction {
  return {
    type: SET_ID,
    idConf,
  };
}

export function setKind(kindConf: string): ToolsAction {
  return {
    type: SET_KIND,
    kindConf,
  };
}

export function setChosenLevel(level: number | null): ToolsAction {
  return {
    type: SET_CHOSEN_LEVEL,
    level,
  };
}

export function setNumberLevels(size: number): ToolsAction {
  return {
    type: SET_NUMBER_LEVELS,
    size,
  };
}

export function setDensity(density: string): ToolsAction {
  return {
    type: SET_DENSITY,
    density,
  };
}
